<template>
  <div>
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Manage Performers !</h5>
      </b-row>         
    </div>

    <!-- Main Tab section start here  -->
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="All Records" active>
          <b-row>
            <b-col sm="12" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
          </b-row>
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="ListAllRec"
                :fields="ListFields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
              <b-button variant="danger" @click="DelTableRec(data.item.seq_no)">D</b-button>
              <b-button variant="success" @click="DelTableRec(data.item.seq_no)">E</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New Record">
          <b-row class="text-center">
            <b-col class="12 my-4">
            <!-- <b-alert show variant="success"  v-if="MsgSuccess">Record created successfully!</b-alert>
            <b-alert show variant="danger"  v-if="MsgDanger">Error! Some Issue while create a new record!</b-alert>                  -->
              <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="profile_name" class="col-8">
                  <b-form-input
                    id="profile_name"
                    v-model="InpForm.profile_name"
                    placeholder="Profile Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_title" class="col-8">
                  <b-form-input
                    id="profile_title"
                    v-model="InpForm.profile_title"
                    placeholder="Profile Title"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group class="col-8">
                <b-form-textarea
                  id="Address"
                  size="md"
                  v-model="InpForm.profile_address"
                  placeholder="Profile Address"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>   
                </b-form-group>               

                <b-form inline class="col-12">
                <b-form-group id="input-group-2" label-for="prof_country">
                  <b-form-input
                    id="prof_country"
                    v-model="InpForm.prof_country"
                    placeholder="Country"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="prof_state" >
                  <b-form-input
                    id="prof_state"
                    v-model="InpForm.prof_state"
                    placeholder="State"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="prof_city">
                  <b-form-input
                    id="prof_city"
                    v-model="InpForm.prof_city"
                    placeholder=" City"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_cntct1">
                  <b-form-input
                    id="profile_cntct1"
                    v-model="InpForm.profile_cntct1"
                    placeholder=" Cntct1"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_cntct2">
                  <b-form-input
                    id="profile_cntct2"
                    v-model="InpForm.profile_cntct2"
                    placeholder="Cntct2"
                  ></b-form-input>                
                </b-form-group>
              </b-form>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_email" >
                  <b-form-input
                    id="profile_email"
                    v-model="InpForm.profile_email"
                    placeholder="Email"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_website">
                  <b-form-input
                    id="profile_website"
                    v-model="InpForm.profile_website"
                    placeholder="Website"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_facebook">
                  <b-form-input
                    id="profile_facebook"
                    v-model="InpForm.profile_facebook"
                    placeholder="Facebook"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_instagram">
                  <b-form-input
                    id="profile_instagram"
                    v-model="InpForm.profile_instagram"
                    placeholder="Instagram"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_youtube">
                  <b-form-input
                    id="profile_youtube"
                    v-model="InpForm.profile_youtube"
                    placeholder="Youtube"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_twitter">
                  <b-form-input
                    id="profile_twitter"
                    v-model="InpForm.profile_twitter"
                    placeholder="Twitter"
                  ></b-form-input>                
                </b-form-group> 
              </b-form>

                <!-- <b-form-group id="input-group-2" label-for="profile_photo" class="col-8 mt-2">
                  <b-form-input
                    id="profile_photo"
                    v-model="InpForm.profile_photo"
                    placeholder="Photo"
                  ></b-form-input>
                </b-form-group> -->

                <b-row class="my-3">
                  <b-col cols="5">         
                     <b-button class="float-left ml-3" variant="success"  @click="click1">Choose a photo
                    </b-button>
                     <input type="file" ref="input1"
                      style="display: none"
                      @change="previewImage" accept="image/*" >   
                  </b-col>
                    <b-col cols="6" >           
                    <b-img v-if="imageData!=null" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
                    </b-img>                
                  </b-col>
                </b-row>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="profile_description"
                  size="md"
                  v-model="InpForm.profile_description"
                  placeholder="Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <!-- Detail Section starts here >> users_profile_detail -->
                <b-form-textarea
                  id="profile_programs"
                  size="md"
                  v-model="InpForm.profile_programs"
                  placeholder="Programs"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_trainings"
                  size="md"
                  v-model="InpForm.profile_trainings"
                  placeholder="Trainings"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_participations"
                  size="md"
                  v-model="InpForm.profile_participations"
                  placeholder="Participations"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_awards"
                  size="md"
                  v-model="InpForm.profile_awards"
                  placeholder="Awards"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>                                               
                </b-form-group>                   

                <b-form-checkbox class="col-8 mt-2"
                  id="checkbox-1"
                  v-model="InpForm.premium_ind"
                  name="checkbox-1"
                  value="Y"
                  unchecked-value="N"
                >
                  Premium Profile?
                </b-form-checkbox>     

                <!-- Media Section starts here >> users_profile_media -->
                 <b-form-group  id="input-group-2" label-for="" class="col-8 mt-2">
                  <b-form-input
                    id="Video1"
                    v-model="InpForm.Video1"
                    placeholder="Video1"
                  ></b-form-input>
                  <b-form-input
                    id="Video2"
                    v-model="InpForm.Video2"
                    placeholder="Video2"
                  ></b-form-input>
                  <b-form-input
                    id="Brochure"
                    v-model="InpForm.Brochure"
                    placeholder="Brochure"
                  ></b-form-input>
                  <b-form-input
                    id="Biodata"
                    v-model="InpForm.Biodata"
                    placeholder="Biodata"
                  ></b-form-input>
                </b-form-group>

                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Reset</b-button>
              </b-form>        
            </b-col>
          </b-row>          

        </b-tab>
        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Inactive Records">
          <div>
            <b-table striped hover outlined responsive="sm"
              :items="ListNewRec"
              :fields="ListFields"
              :per-page="perPage"
              :current-page="currentPage"    
            >
            <template v-slot:cell(actions)="data">
              <b-button variant="info" @click="UpdRecStatus(data.item.seq_no)">A</b-button>
              <b-button variant="danger" @click="DelTableRec(data.item.seq_no)">D</b-button>
              <b-button variant="success" @click="DelTableRec(data.item.seq_no)">E</b-button>
            </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>  
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'

Vue.use(Vueaxios,axios)
  export default {
  props: {
  },
    name: "App",
    data() {
      return {
      ListFields: [{key: 'seq_no',sortable: true}, "profile_name", "prof_city","actions"],      
      // Pagination variables
      perPage: 20,
      currentPage: 1,
      // Input form variables
      InpForm: {
      profile_name: '',
      profile_title: '',
      profile_address: '',
      prof_country: '',
      prof_state: '',
      prof_city: '',
      profile_cntct1: '',
      profile_cntct2: '',
      profile_email: '',
      profile_website: '',
      profile_facebook: '',
      profile_instagram: '',
      profile_youtube: '',
      profile_twitter: '',
      profile_description: '',
      profile_programs: '',
      profile_trainings: '',  
      profile_participations: '',  
      profile_awards: '',  
      catg1: '',  
      catg2: '',  
      catg3: '',  
      catg4: '',  
      catg5: '',  
      premium_ind: 'N',  
      Video1: '',   
      Video2: '',   
      Brochure: '',   
      Biodata: '',   
      },
      // Response array variables
      RespDel: [],
      RespIns: [],
      RespUpdSts: [],
      RespAllRecs: [],
      RespNewRecs: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],
      // Other variables
      show: true,
      SearchBy: '',
      NextSeqNo: '',      
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/artists/perfomr',
      imageData: null,                        
      }
    },
    mounted()
    {
      this.RefreshData()
    },    
    methods: {
      onSubmit(event) {
      event.preventDefault()
      this.InsUserProfile()
      },

      RefreshData() {
        this.AdminAllProfiles()
        this.AdminNewProfiles()
        this.GetMaxID()
      },      

      GetMaxID(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'users_profile_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetMaxID',REQformData)
        .then((resp)=>{
        this.RespGetMaxID=resp.data.GetMaxID;
        const FirstObject = this.RespGetMaxID[0];
        this.NextSeqNo = FirstObject.tbl_next_seq;
        console.log('MaxSeqNo',this.NextSeqNo)
      })
      },

      AdminAllProfiles(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminAllProfiles')
        .then((resp)=>{
        this.RespAllRecs=resp.data.AdminAllProfiles;
      })
      },

      AdminNewProfiles(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=AdminNewProfiles')
        .then((resp)=>{
        this.RespNewRecs=resp.data.AdminNewProfiles;
      })
      },

      InsUserProfile(){  
        const REQformData = new FormData();
        REQformData.append('profile_name', this.InpForm.profile_name);
        REQformData.append('profile_title', this.InpForm.profile_title);
        REQformData.append('profile_address', this.InpForm.profile_address);
        REQformData.append('prof_country', this.InpForm.prof_country);
        REQformData.append('prof_state', this.InpForm.prof_state);
        REQformData.append('prof_city', this.InpForm.prof_city);
        REQformData.append('profile_cntct1', this.InpForm.profile_cntct1);
        REQformData.append('profile_cntct2', this.InpForm.profile_cntct2);
        REQformData.append('profile_email', this.InpForm.profile_email);
        REQformData.append('profile_website', this.InpForm.profile_website);
        REQformData.append('profile_facebook', this.InpForm.profile_facebook);
        REQformData.append('profile_instagram', this.InpForm.profile_instagram);
        REQformData.append('profile_youtube', this.InpForm.profile_youtube);
        REQformData.append('profile_twitter', this.InpForm.profile_twitter);
        REQformData.append('profile_photo', this.PhotoUrl + this.NextSeqNo + '/Perfmr_img.jpeg');
        REQformData.append('profile_description', this.InpForm.profile_description);
        REQformData.append('profile_programs', this.InpForm.profile_programs);
        REQformData.append('profile_trainings', this.InpForm.profile_trainings);
        REQformData.append('profile_participations', this.InpForm.profile_participations);
        REQformData.append('profile_awards', this.InpForm.profile_awards);
        REQformData.append('catg1', this.InpForm.catg1);
        REQformData.append('catg2', this.InpForm.catg2);
        REQformData.append('catg3', this.InpForm.catg3);
        REQformData.append('catg4', this.InpForm.catg4);
        REQformData.append('catg5', this.InpForm.catg5);
        REQformData.append('premium_ind', this.InpForm.premium_ind);
        REQformData.append('Video1', this.InpForm.Video1);
        REQformData.append('Video2', this.InpForm.Video2);
        REQformData.append('Brochure', this.InpForm.Brochure);
        REQformData.append('Biodata', this.InpForm.Biodata);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsUserProfile',REQformData)
        .then((resp)=>{
          this.RespIns=resp.data.message;
          alert('Inserted Successfully!!')
          this.RefreshData()
          this.ClearForms()
      })
      },

      DelTableRec(seq_no){
        const InsformData = new FormData();
        InsformData.append('table_name', 'users_profile_main');
        InsformData.append('seq_no', seq_no);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelTableRec', InsformData)
        .then((resp)=>{
        this.RespDel=resp.data.message;
        alert('Deleted Successfully!!')
        this.RefreshData()
       }).catch(error => console.log(error));
       },

      UpdRecStatus(seq_no){
        const InsformData = new FormData();
        InsformData.append('table_name', 'users_profile_main');
        InsformData.append('seq_no', seq_no);
        InsformData.append('cde_status', 'P');
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=UpdRecStatus', InsformData)
        .then((resp)=>{
        this.RespUpdSts=resp.data.message;
        alert('Approved Successfully!!')
        this.RefreshData()
       }).catch(error => console.log(error));
       },       

      ClearForms(){  
        this.InpForm.profile_name = '';
        this.InpForm.profile_title = '';
        this.InpForm.profile_address = '';
        this.InpForm.prof_country = '';
        this.InpForm.prof_state = '';
        this.InpForm.prof_city = '';
        this.InpForm.profile_cntct1 = '';
        this.InpForm.profile_cntct2 = '';
        this.InpForm.profile_email = '';
        this.InpForm.profile_website = '';
        this.InpForm.profile_facebook = '';
        this.InpForm.profile_instagram = '';
        this.InpForm.profile_youtube = '';
        this.InpForm.profile_twitter = '';
        this.InpForm.profile_description = '';
        this.InpForm.profile_programs = '';
        this.InpForm.profile_trainings = '';  
        this.InpForm.profile_participations = '';  
        this.InpForm.profile_awards = '';  
        this.InpForm.catg1 = '';  
        this.InpForm.catg2 = '';  
        this.InpForm.catg3 = '';  
        this.InpForm.catg4 = '';  
        this.InpForm.catg5 = '';  
        this.InpForm.premium_ind = '';    
        this.InpForm.Video1 = '';   
        this.InpForm.Video2 = '';   
        this.InpForm.Brochure = '';   
        this.InpForm.Biodata = '';   

},                 
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },
// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        this.onUpload()
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('artists/' + 'perfomr'+ this.NextSeqNo +'/' + 'Perfmr_img.jpeg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                this.PhotoPreview =url;
                console.log(this.PhotoPreview)
              });
            }      
          );
       },     
    },
    computed: {
    ListAllRec: function(){
        return this.RespAllRecs.filter((RespAllRecs) => {
        return RespAllRecs.profile_name.toLowerCase().match(this.SearchBy.toLowerCase()) && RespAllRecs.cde_status == 'P'
      });
    },
    ListNewRec: function(){
        return this.RespNewRecs.filter((RespNewRecs) => {
        return RespNewRecs.cde_status != 'P'
      });
    },    
    rows() {
      return this.ListAllRec.length;
    },    
  }    
  }
</script>
